import { Notifier } from "@airbrake/browser";

if (window.env.JEKYLL_ENV === "production") {
  window.airbrake = new Notifier({
    projectId: window.env.AIRBRAKE_PROJECT_ID,
    projectKey: window.env.AIRBRAKE_PROJECT_KEY,
    host: "https://panel.sutty.nl",
  });

  console.originalError = console.error;
  console.error = (...e) => {
    window.airbrake.notify(e.join(" "));
    return console.originalError(...e);
  };
}

import "core-js/stable";
import "regenerator-runtime/runtime";

const Turbolinks = require("turbolinks");
Turbolinks.start();

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import { makeClient } from "@spree/storefront-api-v2-sdk";
import { Sutty } from "./endpoints/sutty";
import { loadEvent } from "./load_event";

window.spree = makeClient({ host: window.env.SPREE_URL });
window.spree.sutty = new Sutty(window.spree.host);

// Prevenir que Turbolinks interfiera con la navegación por anchors
// https://github.com/turbolinks/turbolinks/issues/75#issuecomment-445325162
document.addEventListener("turbolinks:click", (event) => {
  const anchorElement = event.target;
  const isSamePageAnchor =
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname;

  if (!isSamePageAnchor) return;

  Turbolinks.controller?.pushHistoryWithLocationAndRestorationIdentifier(
    event.data.url,
    Turbolinks.uuid()
  );

  event.preventDefault();
});

try {
  window.axe = require("axe-core/axe");
} catch (e) {}

if (window.axe)
  window.axe.configure({ locale: require("axe-core/locales/es.json") });

// Prevenir que Turbolinks interfiera la navegación a un anchor al
// recargar la página
document.addEventListener(loadEvent(), (event) => {
  if (window.location.hash !== "") window.location.hash = window.location.hash;

  /*
   * En Chrome, no se puede hacer scroll horizontal si ya estamos en el
   * tope superior del contenedor, aun cuando el elemento no esté
   * visible.  Para engañarlo, nos movemos 1px adelante y luego
   * realizamos el scroll correspondiente.
   */
  if (window.chrome) {
    document.querySelectorAll(".scroll-into-view").forEach((link) => {
      link.addEventListener("click", (event) => {
        event.stopPropagation();
        event.preventDefault();

        window.scrollTo(0, window.pageYOffset + 1);
        setTimeout(
          () =>
            document
              .querySelector(decodeURIComponent(link.href.replace(/^.*#/, "#")))
              ?.scrollIntoView(),
          50
        );
      });
    });
  }

  document.querySelectorAll(".share").forEach((share) => {
    share.addEventListener("click", (event) => {
      if (!Navigator.share) return;

      event.preventDefault();
      event.stopPropagation();

      const title = document.querySelector("title").text;

      try {
        const text = document.querySelector(
          'meta[property="og:description"]'
        ).content;
      } catch {
        const text = "";
      }

      try {
        const url = document.querySelector("link[rel=canonical]").href;
      } catch {
        const url = document.location.href;
      }

      const data = { title: title, text: text, url: url };

      if (Navigator.canShare(data)) Navigator.share(data).then();
    });
  });

  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      if (a.href.startsWith(window.location.origin)) return;

      a.rel = "noopener";
      a.target = "_blank";
    });

  if (!window.axe) return;

  window.axe.run().then((results) => {
    results.violations.forEach((violation) => {
      violation.nodes.forEach((node) => {
        node.target.forEach((target) => {
          document.querySelectorAll(target).forEach((element) => {
            element.classList.add("inaccesible");
            element.ariaLabel = node.failureSummary;
          });
        });
      });
    });
  });
});
